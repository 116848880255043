/**
 * Type: ページ
 * What: ヒーローショー
 */
import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Button } from '../components/Btn';
import Image from '../util/Image';
import BreadcrumbComponent from '../components/Breadcrumb';
import SimpleSwiper from '../components/Slider.fade';
import mainImg2 from '../images/hero2021/main-heroshow3.jpg';


const pageTitle = '戦隊ショー';
const pageDescription = 'BOSS E・ZO FUKUOKAで機界戦隊ゼンカイジャー　スペシャルステージ開催！子供たちに大人気のゼンカイジャーがやってくる！ここでしか観られないオリジナルストーリーによるスペシャルステージを開催！';
const pageSlug = 'heroshow';
const pageLogo = 'logo-hero.png';
// const modalText = '<p>ふつうのSNS映えでは満足できない人たちの心を満たす、フォトジェニックな空間。飛び交うシュールなうんこやカラフルに光り輝くうんこなど、さまざまなうんこを撮影してみましょう。</p>';

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidEvent
        ticket
        ticketReady
      }
    }
  }
`;

const sliderDataobj = [
  {
    filename: mainImg2,
    noAnimation: true,
  },
];

// Data Props Template
const Template = ({ data }) => {
  const url = data.site.siteMetadata;

  const [isModal, setIsModal] = useState(-1);

  // モーダル展開
  const onClickModal = (e) => {
    const num = Number(e.currentTarget.dataset.modal);
    setIsModal(num);
  };

  // モーダル閉じる
  const onCloseModal = () => {
    setIsModal(-1);
  };

  // モーダルコンテンツ
  const ModalContent = () => (
      <div className={`modal e-modal ${isModal > -1 ? 'is-active' : ''}`}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onCloseModal} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onCloseModal}
        />
        <div className="modal-content c-floorbc-tr1">
          <div className="e-modal-content">
            <h3 className="ttl c-4 tac">
              『機界戦隊ゼンカイジャースペシャルステージ』<br />
              新型コロナウィルス感染症対策に関して
            </h3>
            <p>
              この度、HKT48劇場にて開催いたします『機界戦隊ゼンカイジャースペシャルステージ』におきまして、以下の新型コロナウィルス感染拡大予防対策を講じ、ご来場の皆様及び出演者・公演関係者の安全と安心を確保することに努めてまいります。 ご来場のお客様におかれましても下記のご協力、ご理解お願い申し上げます。
            </p>
            <h4>感染拡大防止策の取り組み </h4>
            <ul>
              <li>●ご入場時に体温チェックを実施させていただきます。37.5 度以上の熱がある方につきましては、 入場をお断りする場合がございます。 </li>
              <li>●入場時はスタッフとの接触を避けるため、お客様ご自身でチケットを切ってお渡しいただきます。 </li>
              <li>●ベビーカー等のお預かりは基本的にお断りしております。</li>
              <li>●出演者へのプレゼント、お手紙などお受け取りはすべてお断りしております。 </li>
              <li>●会場内各所にアルコール消毒液を設置いたします。手指消毒にご協力ください。 </li>
              <li>●感染予防、拡散防止のため、運営スタッフはご対応箇所に応じてマスク・手袋・フェイスガード を着用させていただきます。またグッズ販売等、対面接客を行うカウンター等の箇所には、飛沫 防止のためのクリアパネルなどを設置いたします。 </li>
              <li>●客席および手すりひじ掛けなどお客様の触れる場所は適宜消毒を行います。 </li>
              <li>●開場時間・休憩中はドアの開放、また会場の空調設備などを活用し換気を心がけます。 </li>
              <li>●お座席は前後左右 1 席ずつ間隔をあけた座席配置にてご準備しております。</li>
              <li>●感染予防、拡散防止の為、キャラクターがお客様と直接触れ合う演出、ショー終了後の握手会は実施いたしません。</li>
              <li>●終演後はお客様同士の混雑を避けるため、客席ブロックごとの規制退場にてご案内いたします。</li>
            </ul>
            <h4>ご来場にあたって</h4>
            <ul>
              <li>●咳エチケットをお守りください。<br />来場される際には、3 歳以上の方は、マスクのご着用をお願い申し上げます。</li>
              <li>●以下のような症状がある場合、ご来場をお控えいただきますようお願い申し上げます。 </li>
              <ul>
                <li>１．風邪の症状（くしゃみや咳）や 37.5 度以上の熱がある方 </li>
                <li>２．強いだるさ（倦怠感）や息苦しさがある方</li>
                <li>３．咳、痰、胸部不快感のある方</li>
                <li>４．同居家族や身近な知人の感染が疑われる方</li>
                <li>５．そのほか新型コロナウィルス感染可能性の症状がある方 </li>
                <li>６．14 日以内に、政府から入国制限や入国後の観察期間を必要と発表されている国や地域への 渡航歴のある方、ならびに濃厚接触者の中に該当地域からの渡航者、該当地域在住者がおられる方 </li>
              </ul>
            </ul>
            <h4>観劇における注意事項</h4>
            <ul>
              <li>●声を出しての観劇は禁止とさせていただきます。 手をたたいたり、心の中で応援したり、ライトを振ったりしてヒーローたちへの応援をお願いいたします。 </li>
              <li>●感染症防止対策として、お席に座ったままでご劇いただきますようお願い申し上げます。</li>
            </ul>
            <p>公演について重要なお知らせがある際は、こちらのサイトにて都度ご案内をさせていただきます。</p>
            <h4>お客様情報の利用について </h4>
            <p>万が一新型コロナウィルスに感染されたた方がご来場されていた場合に、ご来場者氏名及び連絡先をチケットご購入のプレイガイドよりご提供いただき、必要に応じて保健所等公的機関に提示させていただく場合がございます。あらかじめご了承ください。 </p>
            <p className="tar mt-5">「機界戦隊ゼンカイジャースペシャルステージ」実行委員会</p>
          </div>
        </div>
      </div>
    );

  const [fetchReady, setFetchReady] = useState(false);

  useEffect(() => {
    setFetchReady(true);
  }, []);

  return (
    <Layout pageSlug={pageSlug}>
      <SEO
        title={pageTitle}
        description={pageDescription}
      />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bg-12 bs-4">

        <div className="kv-wrap">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
            {/* <p className="credit">&copy;「チームラボフォレスト 福岡 - SBI証券」 福岡 E・ZO FUKUOKA</p> */}
          </div>
          <div className="container">
            <div className="inner">
              <div className="pageMain-header">
                <div className="floorNo c-9">
                  <span>1</span>
                  <span>F</span>
                </div>
                <h2 className="ttl">
                  <Image src={pageLogo} alt={pageTitle} />
                </h2>
              </div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                className="col col-4"
                key="team-lab"
                data-modal="0"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                onClick={(e) => onClickModal(e)}
              >
                <div className="btn-group mt-5">
                  <div
                    className="btn-def caution-ticket-btn narrow"
                    // data-sal="slide-up"
                    // data-sal-easing="ease-out-expo"
                  >
                    <span>
                      新型コロナウイルス感染防止の対策について
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section sc-topics">
        <div className="container">
          <div className="inner mb0">
            <h3 className="headline" id="area">
              <span>
                機界戦隊ゼンカイジャー&nbsp;&nbsp;スペシャルステージ開催！<br />
                子供たちに大人気のゼンカイジャーがやってくる！<br />
                3月7日にテレビ番組がスタートした<br className="sp"/>『機界戦隊ゼンカイジャー』<br />
                記念すべき戦隊シリーズ第45作は、<br />一人の”人間”ヒーローと4人の”ロボ”ヒーローが主役<br className="sp"/>というこれまでにない斬新な作品。<br /><span className="attention">アカレンジャー・ツーカイザーの参戦が決定！！</span><br />ここでしか観られないオリジナルストーリーによる<br className="sp"/>スペシャルステージを開催！</span>
            </h3>
          </div>
        </div>
        <div className="inner-slim">
          <div className="table-base">
            <table>
              <tbody>
                <tr>
                  <th>チケット料金</th>
                  <td>
                    <div className="flx-wrap mb-4">
                      <p className="sp-mb0">開催期日：</p>
                      <div>
                        <p className="mb0">9月5日(日)　 9:00～、11:00～、13:00～、15:00～</p>
                        <p className="list-mark"><span>※</span>1日4公演</p>
                      </div>
                    </div>
                    <p>会場：HKT48劇場</p>
                    <div className="sp-flx-wrap flx-wrap mb-4">
                      <p className="sp-mb0">価格：</p>
                      <div>
                        <p className="mb0">3,200円(税込)</p>
                        <p className="list-mark"><span>※</span>3歳以上有料（ただし、3歳未満でもお席が必要な場合は有料）</p>
                      </div>
                    </div>
                    <p className="mb0">チケット販売：ローソンチケット</p>
                    <p className="mb0">ローソンチケット（全席指定）　7/22（木・祝）10:00～一般販売開始</p>
                    <p className="mb0">ローソンチケット　Ｌコード83684</p>
                    <p className="underline-link mb-5">
                      <a
                        href="https://l-tike.com/order/?gLcode=83684"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        チケット購入はこちら
                      </a>
                    </p>
                    <div className="flx-wrap">
                      <p className="sp-mb0">問合せ先：</p>
                      <p>ショーに関するお問い合わせ  <br className="sp" />東映092－532－1082(平日10:00～17:00）<br />チケットに関するお問い合わせ  <br className="sp" />ローチケHPお問い合わせフォームにて</p>
                    </div>
                    <p>主催：機界戦隊ゼンカイジャースペシャルステージ実行委員会</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 className="title is-5">注意事項</h4>
          <article>
            <ul className="list-base">
              <li>当館ご利用の際のお怪我や損失・損害、その他トラブルなどにつきまして当館では一切責任を負いません。</li>
              <li>営業中止を除き、いかなる場合もチケットの払い戻しは行っておりません。</li>
              <li>チケットの転売は禁止しており、転売にて購入いただいたチケットではご入場頂けず、その場合も当館は一切の責任を負いません。</li>
              <li>お子様連れのお客様は、保護者様の監督責任のもと安全にお過ごしください。</li>
              <li>小学生以下のお子様のご入場には、20歳以上の保護者の同伴が必要です。</li>
              <li>※保護者1名に対し未就学児のお子様2名までご入場いただけます。（同伴者もチケット購入が必要です）</li>
              <li>イベントスペースでの喫煙、アメ・ガムを含む飲食はお断りしております。</li>
              <li>会場内にトイレ・休憩室はございません</li>
              <li>体調不良・泥酔状態でのご入場はお断りしております。</li>
              <li>全長50cm以上の荷物は1Fもしくは3Fのコインロッカー、荷物置場をご利用ください</li>
              <li>補助犬を除き、動物を連れてのご入場はお断りしております。</li>
              <li>事前承諾のない商業目的の撮影や取材はお断りしております。</li>
            </ul>
            <h4 className="ttl-strip">持ち込み禁止物</h4>
            <p>
              危険物、臭気物、生花など
              <br />
              ペットボトル、水筒以外の蓋が無いドリンク類
              <br />
              その他、スタッフが危険であると判断した物品
            </p>
          </article>
        </div>
      </section>
      <ModalContent />
    </Layout>
  );
};

export default Template;
